import React from 'react';
import { Button, ButtonProps, HoverStyles, Spinner } from 'wix-ui-tpa/cssVars';
import { useEnvironment } from '@wix/yoshi-flow-editor';
import { classes, st } from './Button.st.css';
import { ButtonAdapterDataHooks } from '../../Widget/datahooks';

export interface ButtonComponentProps extends ButtonProps {
  loading?: boolean;
}

const ButtonComponent: React.FC<ButtonComponentProps> = ({
  children,
  loading,
  ...rest
}) => {
  const { isMobile } = useEnvironment();

  return (
    <div
      className={st(classes.root, { isMobile, loading })}
      data-hook={ButtonAdapterDataHooks.Main}
    >
      <Button
        hoverStyle={HoverStyles.underline}
        upgrade
        disabled={loading}
        {...rest}
      >
        <>
          {loading && (
            <div className={classes.spinnerWrapper}>
              <Spinner
                className={classes.spinner}
                data-hook={ButtonAdapterDataHooks.Spinner}
                isCentered
              />
            </div>
          )}
          <div className={classes.content}>{children}</div>
        </>
      </Button>
    </div>
  );
};

export default ButtonComponent;
